export enum Alpha3 {
    USA = "USA",
    PRI = "PRI",
    CAN = "CAN",
    GBR = "GBR",
    ARE = "ARE",
    AIA = "AIA",
    AUT = "AUT",
    AUS = "AUS",
    BRB = "BRB",
    BEL = "BEL",
    BHR = "BHR",
    BES = "BES",
    BHS = "BHS",
    CHE = "CHE",
    COL = "COL",
    CUW = "CUW",
    CZE = "CZE",
    DEU = "DEU",
    DNK = "DNK",
    DMA = "DMA",
    DOM = "DOM",
    DZA = "DZA",
    EGY = "EGY",
    ESP = "ESP",
    FRA = "FRA",
    GRD = "GRD",
    HUN = "HUN",
    IRL = "IRL",
    IND = "IND",
    ITA = "ITA",
    JOR = "JOR",
    JPN = "JPN",
    KEN = "KEN",
    KOR = "KOR",
    KWT = "KWT",
    LBN = "LBN",
    LUX = "LUX",
    MEX = "MEX",
    NLD = "NLD",
    NOR = "NOR",
    NZL = "NZL",
    OMN = "OMN",
    PHL = "PHL",
    POL = "POL",
    SAU = "SAU",
    SWE = "SWE",
    SGP = "SGP",
    TCA = "TCA",
    THA = "THA",
    TUR = "TUR",
    TTO = "TTO",
    UKR = "UKR",
    VIR = "VIR",
    MAR = "MAR",
    LAO = "LAO",
    MYS = "MYS",
    MMR = "MMR",
    TWN = "TWN",
    VNM = "VNM",
    HKG = "HKG",
    KHM = "KHM",
    IDN = "IDN",
    JAM = "JAM",
    AFG = "AFG",
    ALA = "ALA",
    ALB = "ALB",
    ASM = "ASM",
    AND = "AND",
    AGO = "AGO",
    ATA = "ATA",
    ATG = "ATG",
    ARG = "ARG",
    ARM = "ARM",
    ABW = "ABW",
    AZE = "AZE",
    BGD = "BGD",
    BLR = "BLR",
    BLZ = "BLZ",
    BEN = "BEN",
    BMU = "BMU",
    BTN = "BTN",
    BOL = "BOL",
    BIH = "BIH",
    BWA = "BWA",
    BVT = "BVT",
    BRA = "BRA",
    IOT = "IOT",
    BRN = "BRN",
    BGR = "BGR",
    BFA = "BFA",
    BDI = "BDI",
    CMR = "CMR",
    CPV = "CPV",
    CYM = "CYM",
    CAF = "CAF",
    TCD = "TCD",
    CHL = "CHL",
    CHN = "CHN",
    CXR = "CXR",
    CCK = "CCK",
    COM = "COM",
    COK = "COK",
    CRI = "CRI",
    CIV = "CIV",
    HRV = "HRV",
    CUB = "CUB",
    CYP = "CYP",
    COD = "COD",
    DJI = "DJI",
    ECU = "ECU",
    SLV = "SLV",
    GNQ = "GNQ",
    ERI = "ERI",
    EST = "EST",
    ETH = "ETH",
    FLK = "FLK",
    FRO = "FRO",
    FJI = "FJI",
    FIN = "FIN",
    GUF = "GUF",
    PYF = "PYF",
    ATF = "ATF",
    GAB = "GAB",
    GEO = "GEO",
    GHA = "GHA",
    GIB = "GIB",
    GRC = "GRC",
    GRL = "GRL",
    GLP = "GLP",
    GUM = "GUM",
    GTM = "GTM",
    GGY = "GGY",
    GIN = "GIN",
    GNB = "GNB",
    GCC = "GCC",
    GUY = "GUY",
    HTI = "HTI",
    HMD = "HMD",
    HND = "HND",
    ISL = "ISL",
    IRN = "IRN",
    IRQ = "IRQ",
    IMN = "IMN",
    ISR = "ISR",
    JEY = "JEY",
    KAZ = "KAZ",
    KIR = "KIR",
    KGZ = "KGZ",
    LVA = "LVA",
    LSO = "LSO",
    LBR = "LBR",
    LBY = "LBY",
    LIE = "LIE",
    LTU = "LTU",
    MAC = "MAC",
    MDG = "MDG",
    MWI = "MWI",
    MDV = "MDV",
    MLI = "MLI",
    MLT = "MLT",
    MHL = "MHL",
    MTQ = "MTQ",
    MRT = "MRT",
    MUS = "MUS",
    MYT = "MYT",
    FSM = "FSM",
    MDA = "MDA",
    MCO = "MCO",
    MNG = "MNG",
    MNE = "MNE",
    MSR = "MSR",
    MOZ = "MOZ",
    NAM = "NAM",
    NRU = "NRU",
    NPL = "NPL",
    NCL = "NCL",
    NIC = "NIC",
    NER = "NER",
    NGA = "NGA",
    NIU = "NIU",
    NFK = "NFK",
    PRK = "PRK",
    MNP = "MNP",
    PAK = "PAK",
    PLW = "PLW",
    PAN = "PAN",
    PNG = "PNG",
    PRY = "PRY",
    PER = "PER",
    PCN = "PCN",
    PRT = "PRT",
    QAT = "QAT",
    MKD = "MKD",
    COG = "COG",
    REU = "REU",
    ROU = "ROU",
    RUS = "RUS",
    RWA = "RWA",
    BLM = "BLM",
    SHN = "SHN",
    KNA = "KNA",
    LCA = "LCA",
    MAF = "MAF",
    SPM = "SPM",
    VCT = "VCT",
    WSM = "WSM",
    SMR = "SMR",
    STP = "STP",
    SEN = "SEN",
    SRB = "SRB",
    SYC = "SYC",
    SLE = "SLE",
    SXM = "SXM",
    SVK = "SVK",
    SVN = "SVN",
    SLB = "SLB",
    SOM = "SOM",
    ZAF = "ZAF",
    SGS = "SGS",
    SSD = "SSD",
    LKA = "LKA",
    PSE = "PSE",
    SDN = "SDN",
    SUR = "SUR",
    SJM = "SJM",
    SWZ = "SWZ",
    SYR = "SYR",
    TJK = "TJK",
    TZA = "TZA",
    GMB = "GMB",
    TLS = "TLS",
    TGO = "TGO",
    TKL = "TKL",
    TON = "TON",
    TUN = "TUN",
    TKM = "TKM",
    TUV = "TUV",
    UGA = "UGA",
    UMI = "UMI",
    URY = "URY",
    UZB = "UZB",
    VUT = "VUT",
    VAT = "VAT",
    VEN = "VEN",
    VGB = "VGB",
    WLF = "WLF",
    ESH = "ESH",
    YEM = "YEM",
    ZMB = "ZMB",
    ZWE = "ZWE"
}

export enum Alpha2 {
    US = "US",
    PR = "PR",
    CA = "CA",
    GB = "GB",
    AE = "AE",
    AI = "AI",
    AT = "AT",
    AU = "AU",
    BB = "BB",
    BE = "BE",
    BH = "BH",
    BQ = "BQ",
    BS = "BS",
    CH = "CH",
    CO = "CO",
    CW = "CW",
    CZ = "CZ",
    DE = "DE",
    DK = "DK",
    DM = "DM",
    DO = "DO",
    DZ = "DZ",
    EG = "EG",
    ES = "ES",
    FR = "FR",
    GD = "GD",
    HU = "HU",
    IE = "IE",
    IN = "IN",
    IT = "IT",
    JO = "JO",
    JP = "JP",
    KE = "KE",
    KR = "KR",
    KW = "KW",
    LB = "LB",
    LU = "LU",
    MX = "MX",
    NL = "NL",
    NO = "NO",
    NZ = "NZ",
    OM = "OM",
    PH = "PH",
    PL = "PL",
    SA = "SA",
    SE = "SE",
    SG = "SG",
    TC = "TC",
    TH = "TH",
    TR = "TR",
    TT = "TT",
    UA = "UA",
    VI = "VI",
    MA = "MA",
    LA = "LA",
    MY = "MY",
    MM = "MM",
    TW = "TW",
    VN = "VN",
    HK = "HK",
    KH = "KH",
    ID = "ID",
    JM = "JM",
    AF = "AF",
    AX = "AX",
    AL = "AL",
    AS = "AS",
    AD = "AD",
    AO = "AO",
    AQ = "AQ",
    AG = "AG",
    AR = "AR",
    AM = "AM",
    AW = "AW",
    AZ = "AZ",
    BD = "BD",
    BY = "BY",
    BZ = "BZ",
    BJ = "BJ",
    BM = "BM",
    BT = "BT",
    BO = "BO",
    BA = "BA",
    BW = "BW",
    BV = "BV",
    BR = "BR",
    IO = "IO",
    BN = "BN",
    BG = "BG",
    BF = "BF",
    BI = "BI",
    CM = "CM",
    CV = "CV",
    KY = "KY",
    CF = "CF",
    TD = "TD",
    CL = "CL",
    CN = "CN",
    CX = "CX",
    CC = "CC",
    KM = "KM",
    CK = "CK",
    CR = "CR",
    CI = "CI",
    HR = "HR",
    CU = "CU",
    CY = "CY",
    CD = "CD",
    DJ = "DJ",
    EC = "EC",
    SV = "SV",
    GQ = "GQ",
    ER = "ER",
    EE = "EE",
    ET = "ET",
    FK = "FK",
    FO = "FO",
    FJ = "FJ",
    FI = "FI",
    GF = "GF",
    PF = "PF",
    TF = "TF",
    GA = "GA",
    GE = "GE",
    GH = "GH",
    GI = "GI",
    GR = "GR",
    GL = "GL",
    GP = "GP",
    GU = "GU",
    GT = "GT",
    GG = "GG",
    GN = "GN",
    GW = "GW",
    GC = "GC",
    GY = "GY",
    HT = "HT",
    HM = "HM",
    HN = "HN",
    IS = "IS",
    IR = "IR",
    IQ = "IQ",
    IM = "IM",
    IL = "IL",
    JE = "JE",
    KZ = "KZ",
    KI = "KI",
    KG = "KG",
    LV = "LV",
    LS = "LS",
    LR = "LR",
    LY = "LY",
    LI = "LI",
    LT = "LT",
    MO = "MO",
    MG = "MG",
    MW = "MW",
    MV = "MV",
    ML = "ML",
    MT = "MT",
    MH = "MH",
    MQ = "MQ",
    MR = "MR",
    MU = "MU",
    YT = "YT",
    FM = "FM",
    MD = "MD",
    MC = "MC",
    MN = "MN",
    ME = "ME",
    MS = "MS",
    MZ = "MZ",
    NA = "NA",
    NR = "NR",
    NP = "NP",
    NC = "NC",
    NI = "NI",
    NE = "NE",
    NG = "NG",
    NU = "NU",
    NF = "NF",
    KP = "KP",
    MP = "MP",
    PK = "PK",
    PW = "PW",
    PA = "PA",
    PG = "PG",
    PY = "PY",
    PE = "PE",
    PN = "PN",
    PT = "PT",
    QA = "QA",
    MK = "MK",
    CG = "CG",
    RE = "RE",
    RO = "RO",
    RU = "RU",
    RW = "RW",
    BL = "BL",
    SH = "SH",
    KN = "KN",
    LC = "LC",
    MF = "MF",
    PM = "PM",
    VC = "VC",
    WS = "WS",
    SM = "SM",
    ST = "ST",
    SN = "SN",
    RS = "RS",
    SC = "SC",
    SL = "SL",
    SX = "SX",
    SK = "SK",
    SI = "SI",
    SB = "SB",
    SO = "SO",
    ZA = "ZA",
    GS = "GS",
    SS = "SS",
    LK = "LK",
    PS = "PS",
    SD = "SD",
    SR = "SR",
    SJ = "SJ",
    SZ = "SZ",
    SY = "SY",
    TJ = "TJ",
    TZ = "TZ",
    GM = "GM",
    TL = "TL",
    TG = "TG",
    TK = "TK",
    TO = "TO",
    TN = "TN",
    TM = "TM",
    TV = "TV",
    UG = "UG",
    UM = "UM",
    UY = "UY",
    UZ = "UZ",
    VU = "VU",
    VA = "VA",
    VE = "VE",
    VG = "VG",
    WF = "WF",
    EH = "EH",
    YE = "YE",
    ZM = "ZM",
    ZW = "ZW"
}

export enum Currency {
    USD = "USD",
    CAD = "CAD",
    MXN = "MXN",
    GBP = "GBP",
    EUR = "EUR",
    CHF = "CHF",
    COP = "COP",
    JPY = "JPY"
}

export enum optInNotificationTypes {
    EMAIL = "EMAIL",
    SMS = "SMS"
}
