import useCookiebot from "hooks/useCookiebot";
import Script from "next/script";

interface CookiebotScriptProps {
    cookiebotId: string;
}

export default function CookiebotScript({
    cookiebotId
}: CookiebotScriptProps): JSX.Element {
    const { showOverlay } = useCookiebot();

    return (
        <>
            <Script
                data-cbid={cookiebotId}
                id="Cookiebot"
                src="https://consent.cookiebot.com/uc.js"
                type="text/javascript"
                data-testid="cookiebot-script"
            />
            {showOverlay && (
                <div
                    className="overlay-cookiebot"
                    data-testid="cookiebot-overlay"
                />
            )}
        </>
    );
}
