import {
    PROTECTED_ROUTES,
    PUBLIC_ONLY_ROUTES,
    Route
} from "../constants/routes";

export interface RouteValidationResult {
    shouldRedirect: boolean;
    redirectPath?: string;
}

export function validateProtectedRoute(
    hasUserToken: boolean,
    path: string
): RouteValidationResult {
    if (!hasUserToken && PROTECTED_ROUTES.includes(path as Route)) {
        return { shouldRedirect: true, redirectPath: "/home" };
    }
    return { shouldRedirect: false };
}

export function validatePublicOnlyRoute(
    hasUserToken: boolean,
    path: string
): RouteValidationResult {
    if (hasUserToken && PUBLIC_ONLY_ROUTES.includes(path as Route)) {
        return { shouldRedirect: true, redirectPath: "/register" };
    }
    return { shouldRedirect: false };
}

export function validateThankYouPage(
    path: string,
    orderId?: string,
    noPurchase?: boolean
): RouteValidationResult {
    if (!path?.includes("/thank-you")) return { shouldRedirect: false };

    const orderIdPattern = /^[0-9]{2}-[0-9]{6,20}$/;
    const isValidOrder =
        (typeof orderId === "string" && orderIdPattern.test(orderId)) ||
        noPurchase;

    return {
        shouldRedirect: !isValidOrder,
        redirectPath: !isValidOrder ? "/logout" : undefined
    };
}

export function getRedirectResult(
    protectedRouteCheck: RouteValidationResult,
    publicOnlyRouteCheck: RouteValidationResult,
    thankYouPageCheck: RouteValidationResult
): RouteValidationResult {
    if (protectedRouteCheck.shouldRedirect) return protectedRouteCheck;
    if (publicOnlyRouteCheck.shouldRedirect) return publicOnlyRouteCheck;
    if (thankYouPageCheck.shouldRedirect) return thankYouPageCheck;
    return { shouldRedirect: false };
}
