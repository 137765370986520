enum MixpanelEvent {
    AGREEMENTS_ACCEPTED = "enr_agreements_accepted",
    AGREEMENTS_VALIDATION_ERROR = "enr_agreements_validation_error",
    CONGRATS_LOGIN_TO_OFFICE = "enr_congrats_login_to_office",
    ENROLLMENT_CLICKED = "enr_enrollment_clicked",
    ENROLLMENT_COMPLETED = "enr_enrollment_completed",
    ENROLLMENT_SERVER_ERROR = "enr_enrollment_server_error",
    FEEDBACK_APPEARS = "enr_feedback_appears",
    FEEDBACK_DISMISSED = "enr_feedback_dismissed",
    FEEDBACK_RATED = "enr_feedback_rated",
    FOOTER_LINK_CLICKED = "enr_footer_link_clicked",
    HOME_ALREADY_HAVE_ACCOUNT_CLICKED = "enr_home_already_have_account_clicked",
    HOME_START_COMPLETED = "enr_home_start_completed",
    LOGIN_DONT_HAVE_ACCOUNT = "enr_sign_in_dont_have_account_clicked",
    LOGIN_SIGN_IN_CLICKED = "enr_sign_in_clicked",
    LOGIN_SIGN_IN_SERVER_ERROR = "enr_sign_in_server_error",
    LOGOUT = "enr_logout",
    PAGE_VIEW = "enr_page_view",
    PAYMENT_SELECT_PAYMENT_METHOD = "enr_payment_select_payment_method",
    REGISTER_SECTION_UPDATE_STARTED = "enr_section_update_started",
    REGISTER_STARTER_KIT_SECTION_PRESENTED = "enr_starter_kit_section_presented",
    REGISTER_STARTER_KIT_SELECTED = "enr_starter_kit_selected",
    REGISTRATION_ALREADY_HAVE_ACCOUNT_CLICKED = "enr_membership_registration_already_have_account_clicked",
    REGISTRATION_CLICKED = "enr_membership_registration_clicked",
    REGISTRATION_COMPLETED = "enr_membership_registration_completed",
    REGISTRATION_EMAIL_ALREADY_EXISTS_ERROR = "enr_membership_registration_email_already_exists_error",
    REGISTRATION_SERVER_ERROR = "enr_membership_registration_server_error",
    REGISTRATION_STARTED = "enr_membership_registration_started",
    REGISTRATION_VALIDATION_ERROR_AFTER_CLICK = "enr_membership_registration_validation_error_after_click",
    SHIPPING_DATA_ADDED = "enr_shipping_data_added",
    SHIPPING_VALIDATION_ERROR = "enr_shipping_validation_error",
    SIGN_IN_COMPLETED = "enr_sign_in_completed",
    TEST_EVENT = "test_event",
    USER_LANGUAGE_CHANGED = "enr_user_language_changed",
    USER_MARKET_CHANGED = "enr_user_market_changed"
}

export default MixpanelEvent;
