import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { useCountryConfig } from "Constants/countryConfig";
import { OrderProvider } from "Contexts/OrderContext";
import { ProductProvider } from "Contexts/ProductContext";
import { UserProvider } from "Contexts/UserContext";
import { TranslationProvider } from "Contexts/translation";
import { initializeLoggingService } from "Services/datadog/datadogLoggingService";
import initializeDatadog from "Services/datadog/initializeDatadog";
import { sendEvent } from "Services/googleAnalytics";
import { useAlpha2 } from "Services/locale";
import { initVlog } from "Services/utils/verboseLogger"; // Ensure this import is present to extend console
import globalStyles from "Styles/global";
import { lightTheme } from "Styles/theme/themes";
import AppHead from "components/common/AppHead";
import CookiebotScript from "components/common/CookiebotScript";
import { getCookie, setCookie } from "cookies-next";
import { withLDProvider } from "launchdarkly-react-client-sdk";
import { AppContext as NextAppContext } from "next/app";
import { useRouter } from "next/router";
import {
    ComponentType,
    MutableRefObject,
    ReactNode,
    useEffect,
    useRef
} from "react";
import TagManager from "react-gtm-module";
import { CacheManager } from "utils/cacheManager";
import { KNOWN_ROUTES } from "../constants/routes";
import { clearAllAppData, handleQueryParams } from "../utils/cookieUtils";
import {
    getRedirectResult,
    validateProtectedRoute,
    validatePublicOnlyRoute,
    validateThankYouPage
} from "../utils/routeValidation";
import { useEnroller, useInitialReferrer } from "./_app.hooks";

if (typeof document !== "undefined") {
    TagManager.initialize({
        gtmId: process.env.NEXT_PUBLIC_GTM_ID || ""
    });
}

interface AppProps {
    Component: React.ComponentType<AppProps["pageProps"]>;
    pageProps: {
        userToken: string;
        refIdCookie: string | null;
    };
}

function App({ Component, pageProps }: AppProps): JSX.Element {
    const alpha2 = useAlpha2();
    const countryConfig = useCountryConfig();
    const marketExceptions = countryConfig?.marketExceptions;
    const { query: language } = useRouter();
    const isInitialMount = useRef(true);

    setCookie("useCookiebot", !!marketExceptions?.useCookiebot, {
        maxAge: 60 * 60 * 24 * 365,
        path: "/"
    });

    useInitializeApp(isInitialMount, alpha2, language);

    return (
        <div data-testid="app-root">
            <AppHead />
            {marketExceptions?.useCookiebot && (
                <CookiebotScript
                    cookiebotId={process.env.NEXT_PUBLIC_COOKIEBOT || ""}
                />
            )}
            {globalStyles}
            <CssBaseline />
            <AppProviders>
                <Component {...pageProps} />
            </AppProviders>
        </div>
    );
}

interface AppWrapperProps {
    children?: ReactNode;
    Component: ComponentType<AppProps["pageProps"]>;
    pageProps: AppProps["pageProps"];
}

function AppWrapper(props: AppWrapperProps): JSX.Element {
    return <App {...props} />;
}

function useInitializeApp(
    isInitialMount: MutableRefObject<boolean>,
    alpha2: string,
    language: { [key: string]: string | string[] | undefined }
): void {
    const countryConfig = useCountryConfig();
    const marketExceptions = countryConfig?.marketExceptions;
    const mountRef = useRef(isInitialMount.current);

    initVlog();
    useEnroller();
    useInitialReferrer();

    // Add cache version check on app initialization
    useEffect(() => {
        const handleCacheCheck = async (): Promise<void> => {
            try {
                await CacheManager.handleVersionMismatch();
            } catch (error) {
                console.error("Error handling cache version:", error);
            }
        };

        handleCacheCheck();
    }, []);

    useEffect(() => {
        const shouldSendEvent = !mountRef.current;
        mountRef.current = false;

        if (shouldSendEvent) {
            const languageFromCookie = getCookie("language") || language;
            sendEvent("page_configration", {
                country: alpha2,
                language: languageFromCookie
            });
        }
    }, [alpha2, language]);

    useEffect(() => {
        const isValidEnvironment = ["production", "development"].includes(
            process.env.NEXT_PUBLIC_DATADOG_ENV || ""
        );

        if (!isValidEnvironment) {
            return undefined;
        }

        const handleCookieConsent = (): void => {
            const useCookiebot = !!marketExceptions?.useCookiebot;

            if (!useCookiebot) {
                initializeDatadog();
                initializeLoggingService();
                return;
            }

            const cookieConsent = getCookie("CookieConsent");
            if (!cookieConsent) {
                return;
            }

            try {
                const fixedConsent = cookieConsent
                    .replace(/'/g, '"')
                    .replace(/([a-zA-Z0-9_]+):/g, '"$1":');

                const parsedConsent = JSON.parse(fixedConsent);

                if (parsedConsent?.statistics) {
                    initializeDatadog();
                    initializeLoggingService();
                }
            } catch (error) {
                console.error("Error parsing consent cookie:", error);
            }
        };

        handleCookieConsent();

        window.addEventListener("CookiebotOnDialogDisplay", () => {
            window.addEventListener("CookiebotOnAccept", handleCookieConsent);
        });

        return () => {
            window.removeEventListener("CookiebotOnDialogDisplay", () => {
                window.removeEventListener(
                    "CookiebotOnAccept",
                    handleCookieConsent
                );
            });
        };
    }, [marketExceptions?.useCookiebot]);
}

function AppProviders({ children }: { children: ReactNode }): JSX.Element {
    return (
        <ThemeProvider theme={lightTheme}>
            <TranslationProvider>
                <ProductProvider>
                    <OrderProvider>
                        <UserProvider>{children}</UserProvider>
                    </OrderProvider>
                </ProductProvider>
            </TranslationProvider>
        </ThemeProvider>
    );
}

// เพิ่มฟังก์ชัน AppWrapper.getInitialProps
AppWrapper.getInitialProps = async (
    appContext: NextAppContext
): Promise<{ pageProps: AppProps["pageProps"] }> => {
    const path = appContext.ctx.pathname || "/";
    const defaultPageProps: AppProps["pageProps"] = {
        userToken: "",
        refIdCookie: null
    };

    // Early return if no response object
    if (!appContext.ctx.res) {
        return { pageProps: defaultPageProps };
    }

    // เพิ่มเงื่อนไขสำหรับ reset path
    if (path === "/reset") {
        clearAllAppData(appContext.ctx);
        appContext.ctx.res.setHeader("Content-Type", "text/html");
        appContext.ctx.res.write(`<script>
            sessionStorage.clear();
            window.location.href = '/home';
        </script>`);
        appContext.ctx.res.end();
        return { pageProps: defaultPageProps };
    }

    const userToken =
        getCookie("_unicityToken_v5_enroll", appContext.ctx) ?? "";
    const hasUserToken = !!userToken;
    const refIdCookie = getCookie("refId", appContext.ctx) ?? null;
    const { query } = appContext.ctx;

    handleQueryParams(query, appContext.ctx);

    defaultPageProps.userToken = userToken;
    defaultPageProps.refIdCookie = refIdCookie;

    // Validate routes
    const protectedRouteCheck = validateProtectedRoute(hasUserToken, path);
    const publicOnlyRouteCheck = validatePublicOnlyRoute(hasUserToken, path);
    const thankYouPageCheck = validateThankYouPage(
        path,
        query.orderId as string,
        query.noPurchase as unknown as boolean
    );

    const redirectResult = getRedirectResult(
        protectedRouteCheck,
        publicOnlyRouteCheck,
        thankYouPageCheck
    );

    // Handle redirects
    if (redirectResult.shouldRedirect && redirectResult.redirectPath) {
        appContext.ctx.res.writeHead(302, {
            Location: redirectResult.redirectPath
        });
        appContext.ctx.res.end();
        return { pageProps: defaultPageProps };
    }

    // Handle unknown routes
    const isKnownRoute = KNOWN_ROUTES.some(route => path?.includes(route));
    if (!isKnownRoute) {
        appContext.ctx.res.writeHead(302, { Location: "/home" });
        appContext.ctx.res.end();
        return { pageProps: defaultPageProps };
    }

    return { pageProps: defaultPageProps };
};

const launchDarklyClientSideID =
    process.env.NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_ID || null;
const AppWrapperWithLD = launchDarklyClientSideID
    ? withLDProvider({
          clientSideID: launchDarklyClientSideID,
          context: {
              kind: "user",
              key: "all-users"
          }
      })(AppWrapper as any)
    : AppWrapper;

export default AppWrapperWithLD;
