const spacing = {
    xs: 4,
    sm: 8,
    md: 16,
    lg: 24,
    xl: 32,
    xxl: 50 // Used for padding in some components
} as const;

export default spacing;
