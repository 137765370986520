import { useEffect, useState } from "react";

export default function useCookiebot(): { showOverlay: boolean } {
    const [showOverlay, setShowOverlay] = useState(false);

    useEffect(() => {
        const handleOpenDialog = (): void => {
            setShowOverlay(true);
            document.documentElement.style.overflow = "hidden";
            document.body.style.overflow = "hidden";
        };

        const handleCloseDialog = (): void => {
            setShowOverlay(false);
            document.documentElement.style.overflow = "";
            document.body.style.overflow = "";
        };

        window.addEventListener("CookiebotOnDialogDisplay", handleOpenDialog);
        window.addEventListener("CookiebotOnAccept", handleCloseDialog);
        window.addEventListener("CookiebotOnDecline", handleCloseDialog);

        return () => {
            window.removeEventListener(
                "CookiebotOnDialogDisplay",
                handleOpenDialog
            );
            window.removeEventListener("CookiebotOnAccept", handleCloseDialog);
            window.removeEventListener("CookiebotOnDecline", handleCloseDialog);
        };
    }, []);

    return { showOverlay };
}
